
















import { Component, Mixins } from 'vue-property-decorator'

import { Inject } from '../../../../support'
import { ILoyaltyService, LoyaltyServiceType } from '../../../loyalty/contracts/services'
import { BenefitProgram } from '../../../loyalty/contracts/programs'
import { benefitLogo, MILES_PER_PLN } from '../../../loyalty/shared'
import { BaseCartMixin, IBaseCart } from '../../shared/mixins/base-cart.mixin'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<CartBenefits>({
  name: 'CartBenefits',
  async created () {
    const loyalty = await this.loyaltyService.fetch()
    this.programs = loyalty.getCurrentPrograms()
  }
})
export class CartBenefits extends Mixins<IBaseCart>(BaseCartMixin) {
  @Inject(LoyaltyServiceType)
  protected readonly loyaltyService!: ILoyaltyService

  public programs: BenefitProgram[] = []

  public get hasPrograms (): boolean {
    if (!this.cart) {
      return false
    }

    return !!this.programs.length
  }

  public getLabel (program: BenefitProgram): string {
    const label = `front.checkout.views.CartView.summary.benefits.${program}`

    switch (program) {
      case BenefitProgram.FriendsAndFamily:
      case BenefitProgram.Kameleon:
        return this.$t(label).toString()
      case BenefitProgram.MilesAndMore: {
        if (!this.hasFaF) {
          return this.$t(label).toString()
        }

        const labelWithFaF = `${label}FaF`
        const translation = this.$t(labelWithFaF)

        if (translation !== labelWithFaF) {
          return translation.toString()
        }

        return this.$t(label).toString()
      }
    }
  }

  public getLogo (program: BenefitProgram): string {
    return benefitLogo[program]
  }

  public getValue (program: BenefitProgram): string {
    switch (program) {
      case BenefitProgram.FriendsAndFamily:
        return '31%'
      case BenefitProgram.Kameleon:
        return '10%'
      case BenefitProgram.MilesAndMore: {
        if (!this.cart) {
          return ''
        }
        const count = Math.floor(MILES_PER_PLN * this.cart.total.value)
        return this.$tc('front.checkout.views.CartView.summary.benefits.count', count, { count })
      }
    }
  }

  protected get hasFaF (): boolean {
    return this.programs.includes(BenefitProgram.FriendsAndFamily)
  }
}

export default CartBenefits
