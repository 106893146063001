




































































































import { Component, Mixins, Prop, Inject as VueInject } from 'vue-property-decorator'
import { EventbusType, IEventbus } from '@movecloser/front-core'
import { defaultProvider, Inject, IS_MOBILE_PROVIDER_KEY } from '../../../../support'

import { FullscreenLoader } from '../../../shared/molecules/Loader'
import {
  IProductsRepository,
  ProductsRepositoryType
} from '../../../products/contracts/repositories'
import { IToastMixin, ToastMixin } from '../../../shared'
import { SidesLayout } from '../../../shared/molecules/SidesLayout'
import { StructureConfigurable } from '../../../../support/mixins'

import { RouteName } from '../../routes'
import { SingleCartItem } from '../../organisms/SingleCartItem'

import { BaseCartMixin, IBaseCart } from '../../shared/mixins/base-cart.mixin'
import { CART_SUMMARY_COMPONENT_KEY, CART_SUMMARY_DEFAULT_CONFIG } from './CartSummary.config'
import { CartBenefits } from '../CartBenefits/CartBenefits.vue'
import { CartCoupons } from '../CartCoupons/CartCoupons.vue'
import { CartItemData, ISiteService, SiteServiceType } from '../../../../contexts'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 * @author Filip Rurak <filip.rurak@movecloser.pl> (edited)
 */
@Component<CartSummary>({
  name: 'CartSummary',
  components: {
    CartBenefits,
    CartCoupons,
    SidesLayout,
    SingleCartItem,
    FullscreenLoader
  },
  created (): void {
    this.config = this.getComponentConfig(CART_SUMMARY_COMPONENT_KEY, CART_SUMMARY_DEFAULT_CONFIG)
  }
})
export class CartSummary extends Mixins<IToastMixin, IBaseCart, StructureConfigurable>(ToastMixin,
  BaseCartMixin, StructureConfigurable
) {
  @Inject(ProductsRepositoryType)
  protected readonly productsRepository!: IProductsRepository

  @Inject(EventbusType)
  protected readonly eventBus!: IEventbus

  @Inject(SiteServiceType)
  protected readonly siteService!: ISiteService

  @VueInject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobile!: () => boolean

  @Prop({ type: Boolean, required: true })
  public readonly hasCartItems!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public readonly isCheckout!: boolean

  /**
   * Determines whether shipping methods is selected.
   */
  public get hasShipping (): boolean {
    return !!this.cart && !!this.cart.selectedShippingMethod
  }

  /**
   * Determines whether there are discounts.
   */
  public get hasDiscounts (): boolean {
    if (!this.cart) {
      return false
    }

    return Array.isArray(this.cart.getDiscounts()) && this.cart.getDiscounts().length > 0
  }

  /**
   * Determines the price of the shipping.
   */
  public get shipping (): number {
    return this.cart?.getShippingPrice() ?? 0
  }

  public get showBenefits (): boolean {
    return this.getConfigProperty('showBenefits')
  }

  public get showFreeDeliveryProgress (): boolean {
    return this.getConfigProperty('showFreeDeliveryProgress')
  }

  public get showTitle (): boolean {
    return this.getConfigProperty('showTitle')
  }

  public get changedOverall (): boolean {
    return this.getConfigProperty('changedOverall')
  }

  public get showShippingPreview (): boolean {
    return this.getConfigProperty('showShippingPreview')
  }

  public get couponsLocation (): string {
    return this.getConfigProperty('couponsLocation')
  }

  public get showTaxes (): boolean {
    return this.getConfigProperty('showTaxes')
  }

  public get showCoupons (): boolean {
    return this.getConfigProperty('showCoupons')
  }

  public get shouldDisplayTotalOnMobile (): boolean {
    return this.getConfigProperty('shouldDisplayTotalOnMobile')
  }

  public get subtotalWrappers (): string[] {
    return this.getConfigProperty('subtotalWrappers')
  }

  public get taxWrappers (): boolean {
    return this.getConfigProperty('taxWrappers')
  }

  public get toFreeDelivery (): { value: number; range: number } {
    if (!this.cart) {
      return {
        value: 0,
        range: 100
      }
    }

    return {
      value: Number(Math.max(0, this.cart.getFreeDeliveryFrom() - this.cart.getTotalPrice())
        .toFixed(2)),
      range: (this.cart.getTotalPrice() / this.cart.getFreeDeliveryFrom()) * 100
    }
  }

  public goToCheckout (): void {
    if (this.cart) {
      const getFirstVariant = (item: CartItemData) => {
        return Object.values(item.product.variants)[0]
      }
      const getFirstVariantKey = (item: CartItemData) => {
        const key = Object.keys(item.product.variants)[0] ?? ''
        return (key !== '_') ? key : ''
      }

      this.eventBus.emit(
        'app:cart.view',
        {
          currency: this.cart.total.currency,
          value: this.cart.total?.value,
          coupon: (this.cart.coupons[0]) ? this.cart.coupons[0].code : '',
          items: this.cart.items.map((item) => {
            const variant = getFirstVariant(item)
            const variantSlug = getFirstVariantKey(item)
            return {
              id: variant.sku,
              parent_id: item.product.sku,
              variant: variantSlug,
              name: variant.name,
              category: variant.attributes.mainCategory,
              brand: variant.attributes.brand,
              currency: this.cart?.total.currency,
              price: variant.price.finalPrice,
              quantity: item.quantity,
              url: this.siteService.getActiveSiteAddress() + `${item.product.urlPath}`,
              image: (Array.isArray(variant.images) &&
                variant.images.length > 0)
                ? this.siteService.getActiveSiteAddress() +
                variant.images[0].url : ''
            }
          })
        }
      )
    }

    this.$router.push({ name: `checkout.${RouteName.Checkout}` })
  }
}

export default CartSummary
